<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Alerts</h1>
              <div class="pagehead-link">
                <a href="" class="pl-20">
                <div class="pagehead-link circle red-100">
                  <i class="icon font-red fa-solid fa-link"></i>
                </div>
              </a>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="alert-css" />
              </div>
            </section>
            <div class="divider"></div>

            <section id="statewide-alert">
              <div class="content-band">
                <div class="container mb-40">
                  <h3>Statewide Alert</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. eiusmod tempor incid idunt ut. dolore
                    magna aliqua. Sed do eiusm od tempor incididunt ut. Quod omnis dicta architecto ab sed ex fuga vel suscipit a facilis!
                  </p>
                </div>

                <div>Lorem ipsum</div>
                <CodeView component="alerts" type="statewide-error-alert">
                  <AlertComponent id="statewide-error" />
                </CodeView>

                <div>Lorem ipsum</div>
                <CodeView component="alerts" type="statewide-info-alert">
                  <AlertComponent id="statewide-info" />
                </CodeView>

                <div>Lorem ipsum</div>
                <CodeView component="alerts" type="statewide-warn-alert">
                  <AlertComponent id="statewide-warn" />
                </CodeView>

                <div>Lorem ipsum</div>
                <CodeView component="alerts" type="statewide-success-alert">
                  <AlertComponent id="statewide-success" />
                </CodeView>
              </div>
            </section>


            <section id="page-level-alert">
              <div class="content-band">
                <div class="container mb-40">
                  <h3>Page Level Alert</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eiusmod tempor incid idunt ut dolore
                    magna aliqua. Sed do eiusm od tempor incididunt ut. Quod omnis dicta architecto ab sed ex fuga vel suscipit a facilis!
                  </p>
                </div>

                <CodeView component="alerts" type="page-error-alert">
                  <AlertComponent id="page-level-error" />
                </CodeView>

                <div>Lorem ipsum</div>
                <CodeView component="alerts" type="page-info-alert">
                  <AlertComponent id="page-level-info" />
                </CodeView>

                <div>Lorem ipsum</div>
                <CodeView component="alerts" type="page-warn-alert">
                  <AlertComponent id="page-level-warn" />
                </CodeView>

                <div>Lorem ipsum</div>
                <CodeView component="alerts" type="page-success-alert">
                  <AlertComponent id="page-level-success" />
                </CodeView>
              </div>
            </section>
          </div>
        <StickyTOC />
        </div>
       </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import AlertComponent from "../Alerts/AlertComponent";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
import StickyTOC from "../../StickyTOC";
export default {
  name: "Alert",
  data: function() {
    return {};
  },
  components: {
    CodeView,
    AlertComponent,
    TempLeftNav,
    SubFooter,
    ImportLinkComponent,
    StickyTOC,
  },
};
</script>
