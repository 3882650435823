<template>
  <div class="page-container">
    <div v-if="id === 'statewide-error'">
      <section>
        <div class="error-site-alert content-band max-w1200" role="alert">
          <div class="flex justify-center align-center p-20">
            <div class="alert-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                role="img"
              >
                <title>Error Icon</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <circle class="cls-r1" cx="16" cy="16" r="16" />
                    <circle class="cls-r2" cx="16" cy="22.98" r="2.13" />
                    <path
                      class="cls-r2"
                      d="M17.15,18.72h-2.3a.48.48,0,0,1-.47-.48l-.6-9.18a.48.48,0,0,1,.47-.48h3.5a.48.48,0,0,1,.47.48l-.6,9.18A.48.48,0,0,1,17.15,18.72Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <p class="m-0">
              Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </section>
    </div>

    <div v-if="id === 'statewide-info'">
      <section>
        <div class="info-site-alert content-band max-w1200" role="alert">
          <div class="flex justify-center align-center p-20">
            <div class="alert-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                role="img"
              >
                <title>informative Status Icon</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <circle class="cls-b1" cx="16" cy="16" r="16" />
                    <path
                      class="cls-b2"
                      d="M14.25,21.26V16.74H13V14h4.75v7.26H19V24H13V21.26Zm4-11A2.25,2.25,0,1,1,16,8,2.25,2.25,0,0,1,18.25,10.25Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <p class="m-0">
              Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </section>
    </div>

    <div v-if="id === 'statewide-warn'">
      <section>
        <div class="warn-site-alert content-band max-w1200" role="alert">
          <div class="flex justify-center align-center p-20">
            <div class="alert-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 28"
                role="img"
              >
                <title>Warning Status Icon</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polyline class="cls-y1" points="16 0 32 28 0 28" />
                    <circle class="cls-y2" cx="16" cy="22.49" r="1.82" />
                    <path
                      class="cls-y2"
                      d="M17,18.85H15a.41.41,0,0,1-.41-.41L14.1,10.6a.41.41,0,0,1,.41-.41h3a.41.41,0,0,1,.41.41l-.51,7.84A.41.41,0,0,1,17,18.85Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <p class="m-0">
              Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </section>
    </div>

    <div v-if="id === 'statewide-success'">
      <section>
        <div class="success-site-alert content-band max-w1200" role="alert">
          <div class="flex justify-center align-center p-20">
            <div class="alert-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                role="img"
              >
                <title>Success Icon</title>

                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <circle class="cls-g1" cx="16" cy="16" r="16" />
                    <path
                      class="cls-g2"
                      d="M8.5,17.55,8,17l2.12-2.12.53.53,3.28,3.28,7.54-7.53.54-.54,2.12,2.14-.54.52-9.13,9.12L13.9,23l-.53-.53Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <p class="m-0">
              Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </section>
    </div>
    <!--PAGE LEVEL ALERTS-->
    <div v-if="id === 'page-level-error'">

          <div class="flex align-center error-page-alert" role="alert">
            <div class="alert-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" role="img">
                <title>Error Icon</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <circle class="cls-r1" cx="16" cy="16" r="16" />
                    <circle class="cls-r2" cx="16" cy="22.98" r="2.13" />
                    <path class="cls-r2" d="M17.15,18.72h-2.3a.48.48,0,0,1-.47-.48l-.6-9.18a.48.48,0,0,1,.47-.48h3.5a.48.48,0,0,1,.47.48l-.6,9.18A.48.48,0,0,1,17.15,18.72Z"/>
                  </g>
                </g>
              </svg>
            </div>
            <div class="alert-page-content">
              <h5 class="semibold">Error Status</h5>
              <p class="alert-page-details">
                Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua.
              </p>
            </div>
          </div>

    </div>

    <div v-if="id === 'page-level-info'">
      <section>
        <div class="content-band max-w1200" role="alert">
          <div class="flex align-center info-page-alert">
            <div class="alert-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                role="img"
              >
                <title>informative Status Icon</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <circle class="cls-b1" cx="16" cy="16" r="16" />
                    <path
                      class="cls-b2"
                      d="M14.25,21.26V16.74H13V14h4.75v7.26H19V24H13V21.26Zm4-11A2.25,2.25,0,1,1,16,8,2.25,2.25,0,0,1,18.25,10.25Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div class="plr-10 ptb-20">
              <h5 class="semibold">Informative Status</h5>
              <p class="alert-page-details">
                Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="id === 'page-level-warn'">
      <section>
        <div class="content-band max-w1200" role="alert">
          <div class="flex align-center warn-page-alert">
            <div class="alert-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 28"
                role="img"
              >
                <title>Warning Status Icon</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polyline class="cls-y1" points="16 0 32 28 0 28" />
                    <circle class="cls-y2" cx="16" cy="22.49" r="1.82" />
                    <path
                      class="cls-y2"
                      d="M17,18.85H15a.41.41,0,0,1-.41-.41L14.1,10.6a.41.41,0,0,1,.41-.41h3a.41.41,0,0,1,.41.41l-.51,7.84A.41.41,0,0,1,17,18.85Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div class="plr-10 ptb-20">
              <h5 class="semibold">Warning Status</h5>
              <p class="alert-page-details">
                Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    <div v-if="id === 'page-level-success'">
      <section>
        <div class="content-band max-w1200" role="alert">
          <div class="flex align-center success-page-alert">
            <div class="alert-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                role="img"
              >
                <title>Success Icon</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <circle class="cls-g1" cx="16" cy="16" r="16" />
                    <path
                      class="cls-g2"
                      d="M8.5,17.55,8,17l2.12-2.12.53.53,3.28,3.28,7.54-7.53.54-.54,2.12,2.14-.54.52-9.13,9.12L13.9,23l-.53-.53Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div class="plr-10 ptb-20">
              <h5 class="semibold">Success Status</h5>
              <p class="alert-page-details">
                Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertComponent",
  props: ["id"],
};
</script>
<style scoped>
.cls-r1 {
  fill: #ffa4a4;
}

.cls-r2 {
  fill: #f1353a;
}
.cls-b1 {
  fill: #c4d7e5;
}

.cls-b2 {
  fill: #3d7aa9;
}
.cls-y1 {
  fill: #ffbe0e;
}

.cls-y2 {
  fill: #fef2d2;
}
.cls-g1 {
  fill: #b9d9b8;
}

.cls-g2 {
  fill: #238722;
}
</style>
